body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

.app {
  min-height: 100vh;
  display: grid;
  .content {
    padding: 30px;
    display: grid;
    align-content: center;
    justify-items: center;
    text-align: center;
    gap: 40px;
    img {
      height: 140px;
      @media only screen and (max-width: 700px){
        height: 60px;
      }
    }
    button {
      padding: 14px 20px;
      -webkit-appearance: none;
      border:0;
      border-radius: 8px;
      font-size: 18px;
      color:white;
      background-color: #1781e2;
      cursor: pointer;
    }
    .headings {
      max-width: 800px;
      h1 {
        color: #303030;
        @media only screen and (max-width: 700px){
          font-size: 20px;
        }
      }
      h2 {
        font-weight: 300;
        font-family: sans-serif;
        font-size: 20px;
        color: #676766;
        @media only screen and (max-width: 700px){
          font-size: 14px;
        }
      }
    }
  }
  @media only screen and (max-width: 700px){
    display: unset;
    min-height: 0;
  }
}
